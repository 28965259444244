import React from 'react';
import Layout from '../../components/layout';
import { console0 } from '../../styles/colors';
import ContactForm from '../../components/contact-form';

export default () => (
  <Layout>
    <ContactForm noTopPadding background='transparent' color={console0} />
  </Layout>
);
